
















































import { LastMessage } from '@/components/ChatsBar/types'
import { CallStatus } from '@/store/modules/root/types'
import { Chat } from '@tada-team/tdproto-ts'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    Badges: () => import('@/components/ChatsBar/List/Item/Badges.vue'),
    IconHash: () => import('@tada/icons/dist/IconHash.vue'),
    IconLock: () => import('@tada/icons/dist/IconLock.vue'),
    IconShout: () => import('@tada/icons/dist/IconShout.vue'),
    IconSmartphone:
      () => import('@tada/icons/dist/IconSmartphone.vue'),
    IconUser: () => import('@tada/icons/dist/IconUser.vue'),
    Menu: () => import('@/components/ChatsBar/List/Item/Menu.vue'),
  },
})
export default class Short extends Vue {
  @Prop({ type: Object, required: true }) private readonly chat!: Chat
  @Prop({ type: Boolean }) private readonly isAfk!: boolean
  @Prop({ type: Boolean }) private readonly isMobile!: boolean
  @Prop({ type: Boolean }) private readonly isOnline!: boolean
  @Prop({ type: Boolean }) private readonly isMuted!: boolean
  @Prop({ type: Object }) private readonly lastMessage?: LastMessage
  @Prop({ type: String }) private readonly callStatus?: CallStatus

  @Prop({ type: Boolean }) private readonly showHide?: boolean
  @Prop({ type: Boolean }) private readonly showPin?: boolean
  @Prop({ type: Boolean }) private readonly showSystembot?: boolean

  private get avatarIconComponentName () {
    if (this.chat.chatType === 'direct') {
      return this.isMobile ? 'IconSmartphone' : 'IconUser'
    }

    if (this.chat.chatType === 'group' || this.chat.chatType === 'thread') {
      if (this.chat.readonlyForMembers) return 'IconShout'
      return this.chat.isPublic ? 'IconHash' : 'IconLock'
    }

    throw new Error('Unexpected chat type')
  }
}
